@tailwind base;
@tailwind components;
@tailwind utilities;


.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 800ms ease-in-out 200s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .header-gradient {
    @apply text-3xl font-extrabold tracking-tight text-white sm:text-4xl  text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-purple-900 text-center
  }